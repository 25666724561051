import React, { useState } from 'react'
import './CoverLetter.css'
const CoverLetter = () => {
    const [formData, setFormData] = useState({
        employerName: '',
        companyName: '',
        companyAddress: '',
        city: '',
        state: '',
        zipCode: '',
        positionTitle: '',
        jobSource: '',
        relevantExperience: '',
        previousEmployer: '',
        attractionReason: '',
        phoneNumber: '',
        email: ''
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    }

    const handlePress = () => {
        window.print({ title: "Amit's_cover_letter.pdf" });
    }
    return (
        <div className='cl_body'>
            <div className='cl_fill_content'>
                <h1>Job Application Form</h1>
                <form className='cl_form'>
                    <div>
                        <label htmlFor="employerName">Employer's Name:</label>
                        <input type="text" id="employerName" name="employerName" value={formData.employerName}
                            onChange={handleChange} placeholder="e.g Amit Kumar" />
                    </div>
                    <div>
                        <label htmlFor="companyName">Company Name:</label>
                        <input type="text" id="companyName" name="companyName" value={formData.companyName}
                            onChange={handleChange} placeholder="e.g XYZ Pvt Ltd" />
                    </div>
                    <div>
                        <label htmlFor="companyAddress">Company Address:</label>
                        <input type="text" id="companyAddress" name="companyAddress" value={formData.companyAddress}
                            onChange={handleChange} placeholder="Company Address" />
                    </div>
                    <div>
                        <label htmlFor="city">City:</label>
                        <input type="text" id="city" name="city" value={formData.city} onChange={handleChange}
                            placeholder="Enter City" />
                    </div>
                    <div>
                        <label htmlFor="state">State:</label>
                        <input type="text" id="state" name="state" value={formData.state} onChange={handleChange}
                            placeholder="Enter State" />
                    </div>
                    <div>
                        <label htmlFor="zipCode">ZIP/Postal Code:</label>
                        <input type="text" id="zipCode" name="zipCode" value={formData.zipCode} onChange={handleChange}
                            placeholder="Enter ZIP/Postal Code" />
                    </div>
                    <div>
                        <label htmlFor="positionTitle">Your Position Title:</label>
                        <input type="text" id="positionTitle" name="positionTitle" value={formData.positionTitle}
                            onChange={handleChange} placeholder="e.g Software Enginner" />
                    </div>
                    <div>
                        <label htmlFor="jobSource">Job source:</label>
                        <input type="text" id="jobSource" name="jobSource" value={formData.jobSource} onChange={handleChange}
                            placeholder="e.g., LinkedIn, Indeed" />
                    </div>
                    <div>
                        <label htmlFor="relevantExperience">Relevant Experience:</label>
                        <textarea rows={4} id="relevantExperience" name="relevantExperience" value={formData.relevantExperience}
                            onChange={handleChange} placeholder="Mention Relevant Experience, Skills, or Education"></textarea>
                    </div>
                    <div>
                        <label htmlFor="previousEmployer">Previous Employer:</label>
                        <input type="text" id="previousEmployer" name="previousEmployer" value={formData.previousEmployer}
                            onChange={handleChange} placeholder="Enter Previous Employer" />
                    </div>
                    <div>
                        <label htmlFor="attractionReason">Attraction reason:</label>
                        <textarea rows={4} id="attractionReason" name="attractionReason" value={formData.attractionReason}
                            onChange={handleChange}
                            placeholder="Mention What Specifically Attracts You to the Company or Position"></textarea>
                    </div>

                </form>
            </div>
            <div className="cl print-content">
                <div className="content">
                    <div className="contact-info">
                        <p>Amit Kumar</p>
                        <p>Sector 74</p>
                        <p>Noida, Uttar Pradesh, 201301</p>
                        <p>amit115017@gmail.com</p>
                        <p>+91 9852972838</p>
                    </div>
                    <div className="date-info">
                        <p>22nd October 2021</p>
                    </div>
                    <div className="employer-info">
                        <p>{formData.employerName || "[Employer's Name]"}</p>
                        <p>{formData.companyName || "[Company Name]"}</p>
                        <p>{formData.companyAddress || "[Company Address]"}</p>
                        <p>{formData.city || "[City]"}, {formData.state || "[State]"}, {formData.zipCode || "[Zip]"}</p>
                    </div>
                    <div className="cl__body">
                        <h2>Hi {formData.employerName || "[Employer's Name]"},</h2>
                        <p>
                            I am writing to express my interest in the {formData.positionTitle || "[Position Title]"} position
                            at {formData.companyName || "[Company Name]"}, as advertised on {formData.jobSource ||
                                "[where you found the job posting]"}.
                        </p>
                        <p>
                            With a background in {formData.relevantExperience || "[mention relevant experience, skills, or education]"}, I am confident in my
                            ability to contribute effectively to your team and help {formData.companyName || "[Company Name]"} achieve its goals.
                        </p>
                        <p>
                            During my previous experience at Phnk Networks India Private Limited, I developed strong skills in {formData.previousEmployer || "[mention specific skills or accomplishments relevant to the position]"}. I am particularly drawn to
                            this opportunity at {formData.companyName || "[Company Name]"} because {formData.attractionReason ||
                                "[mention what specifically attracts you to the company or position]"}.
                        </p>
                        <p>
                            I am highly motivated, detail-oriented, and thrive in fast-paced environments. I am excited about
                            the possibility of joining your team and contributing to {formData.companyName || "[Company Name]"}'s
                            continued success.
                        </p>
                        <p>
                            Please find my resume attached for your consideration. I am available for an interview at your
                            earliest convenience and can be reached at +91 9852972838 or via email at amit115017@gmail.com.
                        </p>
                        <p>
                            Thank you for considering my application. I look forward to the opportunity to discuss how my skills
                            and experiences align with the needs of {formData.companyName || "[Company Name]"}.
                        </p>
                        <br />
                        <p>Sincerely,</p>
                        <p>Amit Kumar</p>
                    </div>
                </div>
            </div>
            <button className='resume-download' onClick={() => { handlePress() }}>Print</button>
        </div>
    )
}

export default CoverLetter