
import './App.css';
import AboutMe from './components/AboutMe/AboutMe';
import Contact from './components/Contact/Contact';
import CoverLetter from './components/CoverLetter/CoverLetter';
import Experiance from './components/Experiance/Experiance';
import Footers from './components/Footers/Footers';
import Header from './components/Header/Header';
import Projects from './components/Projects/Projects';
import Resume from './components/Resume/Resume';
import Services from './components/Services/Services';
import Skills from './components/Skills/Skills';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
} from "react-router-dom";

function App() {
  return (
    <Router>

      <Routes>
        <Route
          path="/"
          element={
            <div className="App">
              <Header />
              <AboutMe />
              <Skills />
              <Experiance />
              <Contact />
              <Footers />
            </div>
          }
        ></Route>
        <Route
          path="/resume"
          element={<Resume />}
        ></Route>
        <Route
          path="/cover-letter"
          element={<CoverLetter />}
        ></Route>

      </Routes>
    </Router>
  );
}

export default App;
