// TreeCardList.js

import React from 'react';
import './TreeCardList.css';
import TreeCardView from './TreeCardView';

const TreeCardList = ({ children, ...props }) => {

  return (
    <div className={`tree_card_list`} tint-color={props.tintColor}>
      {children}
      <div className="before" style={{ backgroundColor: props.precedingLineColor || "#000" }}></div>
      <div className="after"></div>
    </div>
  );
};

export default TreeCardList;
