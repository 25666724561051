import React from 'react'
import './Resume.css'
import { ReactComponent as WebsiteSvg } from '../../assets/svgs/website.svg'
import { ReactComponent as MailSvg } from '../../assets/svgs/email-outlined.svg'
import { ReactComponent as PhoneSvg } from '../../assets/svgs/phone.svg'
import { ReactComponent as LocationSvg } from '../../assets/svgs/location.svg'
import { ReactComponent as LinkedInSvg } from '../../assets/svgs/linkedin-outlined.svg'
import { ReactComponent as GithubSvg } from '../../assets/svgs/github.svg'
const Resume = () => {
    const handlePress = () => {
        window.print({ title: "Amit's_resume.pdf" });
    }
    return (
        <div className='resume_body'>
            <div className="resume print-content">
                <header className='resume_header'>
                    <div className='resume_header--left'>
                        <h1 className='resume_header--heading'>AMIT KUMAR</h1>
                        <p>Experienced Full Stack Developer proficient in React.js and Node.js, with soft knowledge in React Native. Skilled in crafting dynamic web and mobile applications, with a focus on exceeding client expectations. Led successful cross-platform mobile app launches and implemented performance optimizations, enhancing user satisfaction.</p>
                    </div>
                    <div class="resume_header--contact-info">
                        <span><a href="https://www.devamit.in" target="_blank">devamit.in</a> <WebsiteSvg width={18} height={18} /></span>
                        <span><a href="mailto:amit115017@gmail.com">amit115017@gmail.com</a> <MailSvg width={18} height={18}/></span>
                        <span><a href='tel:+91 9852972838' target="_blank" >+91 9852972838</a> <PhoneSvg width={18} height={18} /></span>
                        <span>Noida, India <LocationSvg width={18} height={18} /></span>
                        <span><a href='https://www.linkedin.com/in/amit2197' target="_blank">linkedin.com/in/amit2197</a> <LinkedInSvg width={22} height={22} /></span>
                        <span><a href='https://github.com/amit2197' target="_blank" >github.com/amit2197</a> <GithubSvg width={18} height={18} /></span>
                    </div>

                </header>
                <section class="resume_education">
                    <h2>EDUCATION</h2>
                    <div class="resume_education--item">
                        <div>
                            <h3>Dr. B.C. Roy Engineering College <a href="https://bcrec.ac.in/" target='_blank'></a></h3>
                            <p>Bachelor of Technology (B.Tech) in Computer Science</p>
                        </div>
                        <div style={{ textAlign: "right" }}>
                            <p>Durgapur, India</p>
                            <p><strong>July 2015 - February 2020</strong></p>
                        </div>


                    </div>

                </section>
                <section class="resume_skills">
                    <h2>SKILLS SUMMARY</h2>
                    <ul>
                        <li><strong style={{ minWidth: 100, display: 'inline-block' }}>Languages:</strong> Python3, JavaScript, HTML5, CSS3</li>
                        <li><strong style={{ minWidth: 100, display: 'inline-block' }}>Frameworks:</strong> NodeJs, ReactJs, React Native, Flask, Socket.IO</li>
                        <li><strong style={{ minWidth: 100, display: 'inline-block' }}>Tools:</strong> GitHub, BigQuery, Kafka</li>
                        <li><strong style={{ minWidth: 100, display: 'inline-block' }}>Platforms:</strong> AWS, MongoDB, DynamoDB, PostgreSQL, Visual Studio Code</li>
                        <li><strong style={{ minWidth: 100, display: 'inline-block' }}>Soft Skills:</strong> Problem-Solving, Time Management, Critical Thinking, Conflict Resolution, Leadership, </li>
                    </ul>
                </section>
                <section class="resume_experience">
                    <h2>WORK EXPERIENCE</h2>
                    <div style={{ gap: 10, display: "flex", flexDirection: "column" }}>
                        <div class="resume_experience--item">
                            <div>
                                <div>
                                    <h3>Senior Software Engineer</h3>
                                    <p style={{ fontSize: 13, color: "#333333" }}> <a href="https://phnk.in" style={{ fontSize: 13, color: "#333333" }} target='_blank'>@Phnk Networks India Pvt. Ltd</a></p>
                                </div>
                                <p><strong>July 22- Present</strong></p>
                            </div>

                            <ul>
                                <li>Proficient in MySQL database design, DynamoDB setup, AWS configuration, and Git version control.</li>
                                <li>Developed Node.js projects, including APIs with Kafka-based chat functionality.</li>
                                <li>Worked on integrating payment gateways like Razorpay and Google Play for in-app purchases. Additionally, I’ve implemented AI avatar generation and chat features.</li>
                                <li>Implemented real-time messaging systems for enhanced user engagement.</li>
                                <li>Oversee server-side, Android, and web development projects, handling everything from UI to hosting.</li>
                                <li>Played a significant role in website development, including design and architecture.</li>
                            </ul>
                        </div>
                        <div class="resume_experience--item">
                            <div>
                                <div>
                                    <h3>Software Engineer</h3>
                                    <p style={{ fontSize: 13, color: "#333333" }}> <a href="https://tweencraft.com/" style={{ fontSize: 13, color: "#333333" }} target='_blank'>@Kalpkriti Pvt. Ltd</a></p>
                                </div>
                                <p><strong>September 21 - July 22</strong></p>
                            </div>

                            <ul>
                                <li>Redesigned the company website from scratch, ensuring a robust and efficient online presence.</li>
                                <li>Developed and maintained REST APIs using Node.js, Express.js, and MongoDB, addressing bugs and implementing new features.</li>
                                <li>Created automation scripts in Python, enhancing efficiency and resolving issues.</li>
                                <li>Implemented real-time messaging systems for enhanced user engagement.</li>
                                <li>Utilizing BigQuery to design queries, schedule updates, and plot graphs based on the data.</li>
                            </ul>
                        </div>
                    </div>
                </section>
                <section class="resume_projects">
                    <h2>PROJECTS</h2>
                    <div style={{ gap: 10, display: "flex", flexDirection: "column" }}>
                        <div class="resume_project--item">
                            <div>
                                <div>
                                    <h3>Phnk: Comprehensive App, Website, and Server Development </h3>
                                    <p style={{ fontSize: 13, color: "#333333" }}> <a href="https://phnk.in" style={{ fontSize: 13, color: "#333333" }} target='_blank'>@Phnk Networks India Pvt. Ltd</a></p>
                                </div>
                                <p><strong>July 22- Present</strong></p>
                            </div>
                            <ul>
                                <li>Integrated Kafka-based chat functionality, enabling seamless communication among users in real-time.</li>
                                <li>Implemented payment gateway integration with Razorpay and Google Play, streamlining the in-app purchase process.</li>
                                <li>Spearheaded the development of AI avatar generation and chat features, enhancing user interaction and satisfaction.</li>
                                <li>Played a pivotal role in designing and architecting the platform, ensuring scalability and performance.</li>
                            </ul>
                        </div>
                        <div class="resume_project--item">
                            <div>
                                <div>
                                    <h3>AMRITPEX 2023: Comprehensive App, Website, and Server Development</h3>
                                    {/* <p style={{ fontSize: 13, color: "#333333" }}> @Phnk India Pvt. Ltd | <a href="#">LINK</a></p> */}
                                </div>
                                <p><strong>January 23- March 23</strong></p>
                            </div>
                            <ul>
                                <li>Led the development of a full-featured Android app with user signup/login, activity feed, gallery, map directions, and QR code generation.</li>
                                <li>Designed and implemented a secure backend server using Node.js.</li>
                                <li>Designed and implemented a scalable database schema to store user data, activity information, image references.</li>
                            </ul>
                        </div>
                        <div class="resume_project--item">
                            <div>
                                <div>
                                    <h3>Tweencraft: Comprehensive Website and Server Development</h3>
                                    <p style={{ fontSize: 13, color: "#333333" }}> <a href="https://tweencraft.com/" style={{ fontSize: 13, color: "#333333" }} target='_blank'>@Kalpkriti Pvt. Ltd</a></p>
                                </div>
                                <p><strong>September 21- July 22</strong></p>
                            </div>
                            <ul>
                                <li>Directed the redesign of the website from scratch, prioritizing user experience and visual appeal.</li>
                                <li>Developed and managed RESTful APIs using Node.js, Express.js, and MongoDB to ensure smooth data flow and enable new features.</li>
                                <li>Created automation scripts in Python to streamline operations and resolve issues efficiently.</li>
                                <li>Implemented real-time messaging systems to boost user engagement and interaction.</li>
                                <li>Utilized BigQuery for data analysis, enabling informed decision-making through insightful reports and graphs.</li>
                            </ul>
                        </div>
                    </div>

                </section>
                <section class="resume_certificates">
                    <h2>CERTIFICATES</h2>
                    <div style={{ gap: 10, display: "flex", flexDirection: "column" }}>
                        <div class="resume_certificate--item">
                            <div>
                                <div>
                                    <h3>Full Stack Web Developer Nano-degree (<a href="https://udacity.com" style={{ fontSize: 13, color: "#333333" }} target='_blank'>@Udacity</a>)</h3>

                                </div>
                                <p><strong>July 22- Present</strong></p>
                            </div>
                            <p style={{ fontSize: 14, fontWeight: "bold", color: "#333333", textDecoration: "underline" }}>Key Learnings and Achievements:</p>
                            <ul>
                                <li>Developed responsive web applications using HTML, CSS, and JavaScript.</li>
                                <li>Utilized Jinja2 for templating in Flask applications.</li>
                                <li>Created RESTful APIs using Flask.</li>
                                <li>Implemented user authentication and authorization.</li>
                                <li>Designed and managed databases using SQL (PostgreSQL).</li>
                                <li>Performed data modeling and optimized queries for performance.</li>
                                <li>Deployed applications to cloud platforms such as AWS and Heroku.</li>
                                <li>Collaborated with teams using Git and GitHub for version control.</li>
                                <li>certificate 1: <a href="confirm.udacity.com/RNRWQKTG" target="_blank" rel="noopener noreferrer">confirm.udacity.com/RNRWQKTG</a></li>
                                <li>certificate 2: <a href="confirm.udacity.com/HGNNCHE2" target="_blank" rel="noopener noreferrer">confirm.udacity.com/HGNNCHE2</a></li>
                            </ul>
                        </div>
                    </div>
                </section>
            </div>

            <button className='resume-download' onClick={()=>{handlePress()}}>Print</button>
        </div>
    )
}
export default Resume