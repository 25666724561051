import React, { useState } from 'react'
import './Experiance.css'
import TreeCardList from '../../pages/TreeCard/TreeCardList';
import TreeCardView from '../../pages/TreeCard/TreeCardView';
import NavTabs from '../../pages/NavTabs/NavTabs';
import { ReactComponent as SeniorSoftEng } from '../../assets/svgs/seniorsoftware.svg'
import { ReactComponent as SoftEng } from '../../assets/svgs/software.svg'
import { ReactComponent as RocketIcon } from '../../assets/svgs/rocket.svg'
import { ReactComponent as PhnkIcon } from '../../assets/svgs/phnk.svg'
import { ReactComponent as TweencraftIcon } from '../../assets/svgs/tweencraft.svg'
import PhnkAppGif from '../../assets/gif/phnkapp.gif'
import PhnkWebGif from '../../assets/gif/phnkweb.gif'
import BcrecImg from '../../assets/images/bcrec2.jpg'
import TweenWebGif from '../../assets/gif/tweenweb.gif'
import BcrecLogo from '../../assets/svgs/bcreclogo.png'
import Amritpex2023 from '../../assets/gif/amritpex2023.gif'
import { ReactComponent as GooglePlaySvg } from '../../assets/svgs/googleplay.svg'
import { ReactComponent as WebsiteSvg } from '../../assets/svgs/website.svg'
// import AmritpexImg from '../../assets/images/amritpex2023.png'
const Experiance = () => {
  const [tabs, setTabs] = useState(['Education', 'Experience', 'Projects']);
  const [selectedTab, setSelectedTab] = useState('Experience');
  const visitPhnkApp = () => {
    const appUrl = 'https://play.google.com/store/apps/details?id=com.phnk';
    const link = document.createElement('a');
    link.href = appUrl;
    link.target = "_blank"
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

  }
  const visitPhnkWeb = () => {
    const appUrl = 'https://phnk.in';
    const link = document.createElement('a');
    link.href = appUrl;
    link.target = "_blank"
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

  }
  const visitTweenApp = () => {
    const appUrl = 'https://play.google.com/store/apps/details?id=com.kk.chimpoon';
    const link = document.createElement('a');
    link.href = appUrl;
    link.target = "_blank"
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

  }
  const visitTweenWeb = () => {
    const appUrl = 'https://tweencraft.com/';
    const link = document.createElement('a');
    link.href = appUrl;
    link.target = "_blank"
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

  }

  const visitBcrec = () => {
    const appUrl = 'https://bcrec.ac.in/';
    const link = document.createElement('a');
    link.href = appUrl;
    link.target = "_blank"
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

  }
  return (
    <section className='experiance' id='journey'>
      <h1 className='experiance__heading'>My Journey</h1>

      <NavTabs tabs={tabs} setTabs={setTabs} selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
      {
        selectedTab == "Experience" ?
          (
            <TreeCardList>
              <TreeCardView
                precedingIconStyle={{ backgroundColor: "#000" }}
                precedingIcon={<SeniorSoftEng className="tree_card-experience-icon" style={{ width: "100%", height: "100%", }} />}
                cardItem={<div className='card_flex'>
                  <h1 className='card_flex--heading'>Senior Software Engineer</h1>
                  <p className='card_flex--name'>@Phnk Networks India Pvt Ltd</p>
                  <p className='tree_card-card-date card_flex--date'>07/2022 - Present</p>
                  <br />
                  <ul className='card_flex--desc'>
                    <li>Proficient in MySQL database design, DynamoDB setup, AWS configuration, and Git version control.</li>
                    <li>Developed Node.js projects, including APIs with Kafka-based chat functionality.</li>
                    <li>Worked on integrating payment gateways like Razorpay and Google Play for in-app purchases. Additionally, I’ve implemented AI avatar generation and chat features.</li>
                    <li>Implemented real-time messaging systems for enhanced user engagement.</li>
                    <li>Oversee server-side, Android, and web development projects, handling everything from UI to hosting.</li>
                    <li>Played a significant role in website development, including design and architecture.</li>
                  </ul>

                </div>} />
              <TreeCardView
                precedingIconStyle={{ backgroundColor: "#000" }}
                precedingIcon={<SoftEng className="tree_card-experience-icon" style={{ width: "100%", height: "100%", }} />}
                cardItem={<div className='card_flex'>
                  <h1 className='card_flex--heading'>Software Engineer</h1>
                  <p className='card_flex--name'>@Kalpkriti Pvt. Ltd</p>
                  <p className='tree_card-card-date card_flex--date'>09/2021 - 07/2022</p>
                  <br />
                  <ul className='card_flex--desc'>
                    <li>Redesigned the company website from scratch, ensuring a robust and efficient online presence.</li>
                    <li>Developed and maintained REST APIs using Node.js, Express.js, and MongoDB, addressing bugs and implementing new features.</li>
                    <li>Created automation scripts in Python, enhancing efficiency and resolving issues.</li>
                    <li>Utilizing BigQuery to design queries, schedule updates, and plot graphs based on the data.</li>
                  </ul>
                </div>} />
              <TreeCardView isStart={true}
                precedingIconStyle={{ backgroundColor: "#000" }}
                precedingIcon={
                  <RocketIcon className="tree_card-experience-icon" style={{ width: "100%", height: "100%", }} />} />
            </TreeCardList>
          )
          : selectedTab == "Projects"
            ?
            (
              <TreeCardList>
                <TreeCardView
                  precedingIconStyle={{ backgroundColor: "#000", overflow: "hidden" }}
                  precedingIcon={
                    <PhnkIcon className="tree_card-experience-icon" style={{ width: "100%", height: "100%", }} />
                  }
                  cardStyle={{ padding: 0 }}
                  cardItem={
                    <div className='card_flex' style={{ display: 'flex' }}>
                      <div style={{ position: "relative", backgroundColor: "#FEF459" }}>
                        <PhnkIcon className='project__card--icon' />
                        <p style={{
                          position: 'absolute',
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          padding: 12,
                          backgroundColor: "#FEF459aa",
                          width: "100%",
                          textAlign: "center",
                          fontSize: 14,
                          color: "#000"
                        }}>Hover Me</p>
                        <div className="project__gif-overlay">
                          <img src={PhnkAppGif} alt="GIF" />
                        </div>
                      </div>

                      <div style={{ padding: 20 }}>
                        <h1 className='card_flex--heading'>Phnk: Comprehensive App, Website, and Server Development</h1>
                        <p className='card_flex--name'>@Phnk India Pvt. Ltd</p>
                        <p className='tree_card-card-date card_flex--date'>07/2022 - current</p>
                        <br />
                        <p className='card_flex--desc1'>Phnk is a friendly online space where you can meet new people and talk about anything you like.</p>
                        <div className='project__card--desc-btns'>
                          <GooglePlaySvg className="btn-icon" onClick={() => { visitPhnkApp() }} width={40} height={40} />
                          <WebsiteSvg className="btn-icon" onClick={() => { visitPhnkWeb() }} width={40} height={40} stroke='#fff' />
                        </div>
                      </div>
                    </div>} />



                <TreeCardView
                  precedingIconStyle={{ backgroundColor: "#000", overflow: "hidden" }}
                  precedingIcon={
                    // <AmritpexImg className="tree_card-experience-icon" style={{ width: "100%", height: "100%", }} />
                    <img src={require('../../assets/images/amritpex2023.png')} className="tree_card-experience-icon" style={{ width: "100%", height: "100%", }} alt="" srcset="" />
                  }
                  cardStyle={{ padding: 0 }}
                  cardItem={
                    <div className='card_flex' style={{ display: 'flex' }}>
                      <div style={{ position: "relative", backgroundColor: "#103993", minWidth: 140 }}>
                        {/* <AmritpexImg className='project__card--icon' /> */}
                        <img src={require('../../assets/images/amritpex2023.png')} className='project__card--icon' alt="" srcset="" />
                        <p style={{
                          position: 'absolute',
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          padding: 12,
                          backgroundColor: "#103993aa",
                          width: "100%",
                          textAlign: "center",
                          fontSize: 14,
                          color: "#fff",

                        }}>Hover Me</p>
                        <div className="project__gif-overlay">
                          <img src={Amritpex2023} alt="GIF" />
                        </div>
                      </div>

                      <div style={{ padding: 20 }}>
                        <h1 className='card_flex--heading'>AMRITPEX 2023: Comprehensive App, Website, and Server Development</h1>
                        {/* <p className='card_flex--name'>@Kalpkriti Pvt. Ltd</p> */}
                        <p className='tree_card-card-date card_flex--date'>01/2023 - 03/2023</p>
                        <br />
                        <p className='card_flex--desc1'>AMRITPEX-2023 is a National Philately Exhibition for celebrating Azadi Ka Amrit Mahotsav which will be held from 11th to 15th February, 2023.</p>

                        <p className='card_flex--not_avail_link'>App or Website links not available</p>

                      </div>
                    </div>} />



                <TreeCardView
                  precedingIconStyle={{ backgroundColor: "#000", overflow: "hidden" }}
                  precedingIcon={<TweencraftIcon className="tree_card-experience-icon" style={{ width: "100%", height: "100%", }} />}
                  cardStyle={{ padding: 0 }}
                  cardItem={<div className='card_flex' style={{ display: 'flex' }}>
                    <div style={{ position: "relative", backgroundColor: "#32B1E2", minWidth: 140 }}>
                      <TweencraftIcon className='project__card--icon' />
                      <p style={{
                        position: 'absolute',
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        padding: 12,
                        backgroundColor: "#32B1E2aa",
                        width: "100%",

                        textAlign: "center",
                        fontSize: 14,
                        color: "#000"
                      }}>Hover Me</p>
                      <div className="project__gif-overlay">
                        <img src={TweenWebGif} alt="GIF" />
                      </div>
                    </div>
                    <div style={{ padding: 20 }}><h1 className='card_flex--heading'>Tweencraft: Comprehensive Website and Server Development</h1>
                      <p className='card_flex--name'>@Kalpkriti Pvt. Ltd</p>
                      <p className='tree_card-card-date card_flex--date'>09/2021 - 07/2022</p>
                      <br />
                      <p className='card_flex--desc1'>Tweencraft: 2D cartoon animation app simplifies video & comic creation, no drawing skills needed. Customizable characters, voice recording, & social media sharing.</p>
                      <div className='project__card--desc-btns'>
                        <GooglePlaySvg className="btn-icon" onClick={() => { visitTweenApp() }} width={40} height={40} />
                        <WebsiteSvg className="btn-icon" onClick={() => { visitTweenWeb() }} width={40} height={40} stroke='#fff' />
                      </div>
                    </div>
                  </div>} />
                <TreeCardView isStart={true}
                  precedingIconStyle={{ backgroundColor: "#000" }}
                  precedingIcon={
                    <RocketIcon className="tree_card-experience-icon" style={{ width: "100%", height: "100%", fill: "red" }} />} />
              </TreeCardList>
            ) :
            (<TreeCardList>
              <TreeCardView
                precedingIconStyle={{ backgroundColor: "#000", overflow: "hidden" }}
                precedingIcon={
                  <img src={BcrecLogo} className="tree_card-experience-icon" style={{ width: "100%", height: "100%", }} />
                  // <BcrecLogo className="tree_card-experience-icon" style={{ width: "100%", height: "100%", }} />
                }
                cardStyle={{ padding: 0 }}
                cardItem={<div className='card_flex' style={{ display: 'flex' }}>
                  <div style={{ position: "relative", backgroundColor: "#32B1E2", minWidth: 140, maxWidth: 240 }}>
                    {/* <BcrecImg className='project__card--icon' /> */}
                    <img src={BcrecImg} className='project__card--icon' alt="" srcset="" />

                  </div>

                  <div style={{ padding: 20 }}><h1 className='card_flex--heading'>Bachelor of Technology (B.Tech) in Computer Science</h1>
                    <p className='card_flex--name'>@Dr. B.C. Roy Engineering College, Durgapur</p>
                    <p className='tree_card-card-date card_flex--date'>07/2015 - 02/2020</p>
                    <br />
                    <div className='project__card--desc-btns'>
                        <WebsiteSvg className="btn-icon" onClick={() => { visitBcrec() }} width={40} height={40} stroke='#fff' />
                      </div>
                  </div>
                </div>} />
              <TreeCardView isStart={true}
                precedingIconStyle={{ backgroundColor: "#000" }}
                precedingIcon={
                  <RocketIcon className="tree_card-experience-icon" style={{ width: "100%", height: "100%", fill: "red" }} />} />
            </TreeCardList>
            )
      }

    </section>
  )
}

export default Experiance