import React, { useEffect, useState } from 'react';
import './NavTabs.css';

const NavTabs = ({ tabs, setTabs, selectedTab, setSelectedTab }) => {
  const handleTabClick = (tabName) => {
    setSelectedTab(tabName);
  };



  useEffect(() => {
    const selectedIndex = tabs.indexOf(selectedTab);
    const screenWidth = window.innerWidth;
    if (selectedIndex !== -1) {
      const newArr = [...tabs];
      newArr.splice(selectedIndex, 1);
      if (screenWidth < 1170) {
        newArr.splice(0, 0, selectedTab);
      } else {
        newArr.splice(1, 0, selectedTab);
      }

      setTabs(newArr);
    }
  }, [selectedTab])

  return (
    <div className="nav-tabs">
      {tabs.map((tab) => (
        <div className="tab-item" key={tab}>
          <button
            className={selectedTab === tab ? 'active' : ''}
            onClick={() => handleTabClick(tab)}
          >
            <div className={selectedTab === tab ? 'active_before' : ''}></div>
            <div className={selectedTab === tab ? 'active_after' : ''}></div>
            {tab}
          </button>
        </div>
      ))}
    </div>
  );
};

export default NavTabs;
