import React, { useEffect, useRef, useState } from 'react'
import './aboutMe.css'
import pic from '../../assets/images/pic1.jpg'
import pdfFile from '../../assets/resume.pdf'
const AboutMe = () => {
    const [scrollY, setScrollY] = useState(0);
    const [offset, setOffset] = useState({ top: 0, bottom: 0 });


    const updateOffsets = () => {
        if (window.innerWidth > 585) {
            const aboutSection = document.getElementById('about');
            const topOffset = aboutSection.offsetTop;
            const bottomOffset = aboutSection.offsetHeight + topOffset - window.innerHeight;


            setOffset({ top: topOffset - 35, bottom: bottomOffset + 35 });
        }
    };

    useEffect(() => {
        // const handleScroll = () => {
        //     setScrollY(window.scrollY > 765 ? 345 : window.scrollY < 445 ? 0 : window.scrollY - 445);
        // };

        const handleScroll = () => {
            if (window.innerWidth > 585) {
                const scrollPosition = window.scrollY;
                let newScrollY;
                if (scrollPosition < offset.top) {
                    newScrollY = 0;
                } else if (scrollPosition > offset.bottom) {
                    newScrollY = offset.bottom - offset.top;
                } else {
                    newScrollY = scrollPosition - offset.top;
                }
                setScrollY(newScrollY);
            }
        };
        updateOffsets();

        window.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', updateOffsets);

        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('resize', updateOffsets);
        };
    }, [offset.top, offset.bottom]);


    const downloadPdf = () => {
        const link = document.createElement('a');
        link.href = pdfFile;
        link.download = 'devamit_resume.pdf';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const visitResume = () => {
        const appUrl = '/resume';
        const link = document.createElement('a');
        link.href = appUrl;
        link.target = "_blank"
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }



    return (
        <div className='about' id='about'>
            <div className='aboutme-profile_pic'>
                <img style={{ transform: `translateY(${scrollY}px)` }} className='aboutme-profile_pic--img' src={pic} />
            </div>
            <div className='about-container'>
                <h1 className="about__heading">Who I Am</h1>
                <p className='about__desc'>Experienced Full Stack Developer proficient in crafting dynamic web and mobile applications.</p>
                <h3>Professional Summary</h3>
                <ul>
                    <li><b style={{ fontWeight: 600 }}>Frontend:</b> Skilled in React.js for creating interactive user interfaces with HTML, CSS, and JavaScript.</li>
                    <li><b style={{ fontWeight: 600 }}>Backend:</b> Proficient in Node.js with expertise in Express.js and Flask for robust server-side development.</li>
                    <li><b style={{ fontWeight: 600 }}>Mobile:</b> Experienced in React Native for cross-platform mobile application development.</li>
                    <li><b style={{ fontWeight: 600 }}>Databases:</b> Strong proficiency in MongoDB, MySQL, PostgreSQL, and DynamoDB for efficient data management.</li>
                </ul>
                <p className='about__desc'>
                    Proven ability to deliver high-quality software solutions that exceed client expectations.
                </p>
                <h3>Key Achievements</h3>
                <ul>
                    <li>Successfully Led a team of developers to successfully launch a cross-platform mobile app.</li>
                    <li>Implemented performance optimizations for a web application, resulting in a 50% decrease in loading times and improved user satisfaction.</li>
                </ul>
                <div onClick={() => { visitResume() }} className='about__btn' data-hover="Visit">
                    Visit Resume
                </div>
            </div>
        </div>
    )
}

export default AboutMe