import React, { useState } from 'react'
import './Contact.css'
import { ReactComponent as WhatsappIcon } from '../../assets/svgs/whatsapp.svg'
import { ReactComponent as GmailIcon } from '../../assets/svgs/gmail.svg'
import { ReactComponent as MailIcon } from '../../assets/svgs/mail.svg'
import { ReactComponent as PhoneIcon } from '../../assets/svgs/call.svg'
import { ReactComponent as LinkedInIcon } from '../../assets/svgs/linkedin.svg'
import { ReactComponent as CopyIcon } from '../../assets/svgs/copy.svg'
import Tooltip from '../../pages/Tooltips/Tooltip'
const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: ''
  });
  const [responseMessage, setResponseMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formData)
    try {
      // Make POST request to your API endpoint
      const response = await fetch("https://7ar58vzwth.execute-api.us-east-1.amazonaws.com/default/send_msg", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      const responseData = await response.json();
      if (response.ok) {
        // Handle successful response
        setResponseMessage(responseData.message);
        setErrorMessage('');
        console.log('Message sent successfully!');
      } else {
        // Handle error response
        setResponseMessage('');
        setErrorMessage(responseData.error);
        console.error('Failed to send message');
      }
    } catch (error) {
      // Handle network error
      setResponseMessage('');
      setErrorMessage('Error sending message');
      console.error('Error:', error);
    }
  };
  return (
    <section className='contacts'>
      <div className='contacts__touch'>
        <h1>Get in Touch</h1>
        <p>For inquiries or project collaborations, please contact me using the information below or the contact form</p>
        <div className='contacts__touch--social_icons'>
          <Tooltip text="Phone: +91 9852972838">
            <CopyIcon onClick={() => { navigator.clipboard.writeText('+91 9852972838') }} className='contacts__touch--copy_icon' fill='#fff' width={26} height={26} />
            <div className='contacts__touch--social_icon' onClick={() => { window.open(`tel:+91 9852972838`) }}>

              <PhoneIcon className="contacts__touch--svg_icon" />
              <div className='contacts__touch--icon-desc' data-text="Call">Call</div>
            </div>
          </Tooltip>
          <Tooltip text="Chat on WhatsApp: +91 9852972838">
            <CopyIcon onClick={() => { navigator.clipboard.writeText('+919852972838') }} className='contacts__touch--copy_icon' fill='#fff' width={26} height={26} />
            <div className='contacts__touch--social_icon' onClick={() => { window.open(`https://wa.me/+919852972838`, '_blank') }}>
              <WhatsappIcon className="contacts__touch--svg_icon" />
              <div className='contacts__touch--icon-desc' data-text="WhatsApp">WhatsApp</div>
            </div>
          </Tooltip>
          <Tooltip text="Contact via email: amit115017@gmail.com">
            <CopyIcon onClick={() => { navigator.clipboard.writeText('amit115017@gmail.com') }} className='contacts__touch--copy_icon' fill='#fff' width={26} height={26} />
            <div className='contacts__touch--social_icon' onClick={() => { window.open(`mailto:amit115017@gmail.com`) }}>
              <GmailIcon className="contacts__touch--svg_icon" />
              <div className='contacts__touch--icon-desc' data-text="Gmail">Gmail</div>
            </div>
          </Tooltip>
          <Tooltip text="Contact via email: hi@devamit.in">
            <CopyIcon onClick={() => { navigator.clipboard.writeText('hi@devamit.in') }} className='contacts__touch--copy_icon' fill='#fff' width={26} height={26} />
            <div className='contacts__touch--social_icon' onClick={() => { window.open(`mailto:hi@devamit.in`) }}>
              <MailIcon className="contacts__touch--svg_icon" />
              <div className='contacts__touch--icon-desc' data-text="Email">Email</div>
            </div>
          </Tooltip>
          <Tooltip text="LinkedIn: https://www.linkedin.com/in/amit2197/">
            <CopyIcon onClick={() => { navigator.clipboard.writeText('https://www.linkedin.com/in/amit2197/') }} className='contacts__touch--copy_icon' fill='#fff' width={26} height={26} />
            <div className='contacts__touch--social_icon'>
              <LinkedInIcon className="contacts__touch--svg_icon" onClick={() => { window.open(`https://www.linkedin.com/in/amit2197/`, "_blank") }} />
              <div className='contacts__touch--icon-desc' data-text="LinkedIn">LinkedIn</div>
            </div>

          </Tooltip>
        </div>
      </div>
      <div className='contacts__form'>
        <form onSubmit={handleSubmit}>
          <div className='contacts__form__wrapper'>
            <label htmlFor="name">Name</label>
            <div className='contacts__form--input-container'>
              <i className="fas fa-user"></i>
              <input
                type="text"
                id="name"
                name="name"
                placeholder="e.g John Doe"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </div>
          </div>
          <div className='contacts__form__wrapper'>
            <label htmlFor="email">Email</label>
            <div className='contacts__form--input-container'>
              <i className="fas fa-envelope"></i>
              <input
                type="email"
                id="email"
                name="email"
                placeholder="e.g johndoe@mail.com"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
          </div>
          <div className='contacts__form__wrapper'>
            <label htmlFor="phone">Phone</label>
            <div className='contacts__form--input-container'>
              <i className="fas fa-phone"></i>
              <input
                type="tel"
                id="phone"
                name="phone"
                placeholder="Phone Number"
                value={formData.phone}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className='contacts__form__wrapper'>
            <label htmlFor="message">Message</label>
            <div className='contacts__form--input-container'>
              <i className="fas fa-comment"></i>
              <textarea
                id="message"
                name="message"
                placeholder="Write message..."
                value={formData.message}
                onChange={handleChange}
                // rows={4}
                required
              />
            </div>
          </div>
          <button type="submit" className='contact__form--btn'>Send</button>
          {responseMessage ? <p style={{ color: "green", fontSize: 12, fontStyle: "italic" }}>{responseMessage}</p> : errorMessage ? <p style={{ color: "red", fontSize: 12, fontStyle: "italic" }}>{errorMessage}</p> : <p style={{ fontSize: 12, fontStyle: "italic" }}> </p>}
        </form>
      </div>
    </section>
  )
}

export default Contact