import React, { useEffect, useRef, useState } from 'react'
import './TreeCard.css'
const TreeCardView = ({ ...props }) => {
    const ref = useRef()
    const [inView, setInView] = useState(true)
    useEffect(() => {
        const observer = new IntersectionObserver(
            ([Entry]) => {
                if (Entry.isIntersecting) {
                    setInView(true)
                    // observer.unobserve(ref.current)
                } else {
                    setInView(false)
                }
            },
            {
                threshold: 0.2
            }
        );
        if (ref.current) {
            observer.observe(ref.current)
        }
        return () => {
            if (ref.current) {
                observer.unobserve(ref.current)
            }
        }
    }, [])

    if (props.isStart == true) {
        return (
            <div ref={ref} style={{marginBottom: 30}} className='tree_card-element'>
                <span className={`tree_card-icon ${inView ? "bounce-in" : "is-hidden"}`}
                    style={{
                        backgroundColor: "#242F3E",
                        color: "rgb(255, 255, 255)",
                        textAlign: "center",
                        boxShadow: `0 0 0 4px #000, inset 0 2px 0 rgba(0, 0, 0, 0.08), 0 3px 0 4px rgba(0, 0, 0, 0.05)`,
                        WebkitBoxShadow: `0 0 0 4px #000, inset 0 2px 0 rgba(0, 0, 0, 0.08), 0 3px 0 4px rgba(0, 0, 0, 0.05)`,
                        ...props.precedingIconStyle
                    }}>
                        {props.precedingIcon ?? props.precedingIcon}
                    {/* <i className="fas fa-hourglass-start tree_card-experience-icon" style={{ color: "#000" }}></i> */}
                </span>
            </div>
        )
    }
    return (
        <div ref={ref} className='tree_card-element'>
            <span className={`tree_card-icon ${inView ? "bounce-in" : "is-hidden"}`}
                style={{
                    backgroundColor: "rgb(174, 148, 79)",
                    color: "rgb(255, 255, 255)",
                    textAlign: "center",
                    boxShadow: `0 0 0 4px #000, inset 0 2px 0 rgba(0, 0, 0, 0.08), 0 3px 0 4px rgba(0, 0, 0, 0.05)`,
                    WebkitBoxShadow: `0 0 0 4px #000, inset 0 2px 0 rgba(0, 0, 0, 0.08), 0 3px 0 4px rgba(0, 0, 0, 0.05)`,
                    ...props.precedingIconStyle
                }}>
                {props.precedingIcon ?? props.precedingIcon}
                {/* <i className="fab fa-angular experience-icon"></i> */}
            </span>

            <div className={`tree_card-card ${inView ? "bounce-in" : "is-hidden"}`} style={{...props.cardStyle}}>
                <div className="tree_card-card-arrow"></div>
                {props.cardItem ?? props.cardItem}
                {/* <span className="tree_card-card-date">10.2019 - present</span> */}
            </div>
        </div>
    )
}

export default TreeCardView