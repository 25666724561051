import React, { useState, useRef, useEffect } from 'react';
import './Carousel.css';

const Carousel = ({ data }) => {
  const [containerWidth, setContainerWidth] = useState(0);
  const [itemsToShow, setItemsToShow] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [startX, setStartX] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const containerRef = useRef(null);
  const itemRef = useRef(null);

  useEffect(() => {
    if (containerRef.current && itemRef.current) {
      const containerWidth = containerRef.current.offsetWidth;
      const itemWidth = itemRef.current.offsetWidth;
      const itemsToShow = Math.floor(containerWidth / itemWidth);
      setContainerWidth(containerWidth);
      setItemsToShow(itemsToShow);
    }
  }, [data]);

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartX(e.pageX - containerRef.current.offsetLeft);
  };

  const handleMouseMove = (e) => {
    // if (!isDragging) return;
    // const x = e.pageX - containerRef.current.offsetLeft;
    // const dragDistance = x - startX;
    // const dragPercentage = (dragDistance / containerWidth) * 100;
    // const indexOffset = Math.round(dragPercentage / (100 / itemsToShow));
    // const newIndex = currentIndex - indexOffset;
    // setCurrentIndex(newIndex);
    if (!isDragging) return;
    const dragDistance = e.pageX - startX;
    const dragPercentage = (dragDistance / containerWidth) * 100;
    const indexOffset = Math.round(dragPercentage / (100 / itemsToShow));
    const newIndex = currentIndex - indexOffset;

    if (newIndex < 0) {
      // if (currentIndex == 0){
      //   setCurrentIndex(data.length - itemsToShow);
      // }else{
      setCurrentIndex(0);
      // }

    } else if (newIndex > data.length - itemsToShow) {
      // if (currentIndex == data.length){
      //   setCurrentIndex(0);
      // }else{
      setCurrentIndex(data.length - itemsToShow);
      // }

    } else {
      setCurrentIndex(newIndex);
    }
  };
  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleTouchStart = (e) => {
    setIsDragging(true);
    setStartX(e.touches[0].pageX - containerRef.current.offsetLeft);
  };

  const handleTouchMove = (e) => {
    // if (!isDragging) return;
    // const x = e.touches[0].pageX - containerRef.current.offsetLeft;
    // const dragDistance = x - startX;
    // const dragPercentage = (dragDistance / containerWidth) * 100;
    // const indexOffset = Math.round(dragPercentage / (100 / itemsToShow));
    // const newIndex = currentIndex - indexOffset;
    // setCurrentIndex(newIndex);
    if (!isDragging) return;
    const dragDistance = e.touches[0].pageX - startX;
    const dragPercentage = (dragDistance / containerWidth) * 100;
    const indexOffset = Math.round(dragPercentage / (100 / itemsToShow));
    const newIndex = currentIndex - indexOffset;

    if (newIndex < 0) {
      setCurrentIndex(0);
    } else if (newIndex > data.length - itemsToShow) {
      setCurrentIndex(data.length - itemsToShow);
    } else {
      setCurrentIndex(newIndex);
    }
  };

  const handleTouchEnd = () => {
    setIsDragging(false);
  };

  const nextSlide = () => {
    const lastIndex = data.length - itemsToShow;
    const shouldResetIndex = currentIndex === lastIndex;
    const index = shouldResetIndex ? 0 : currentIndex + 1;
    setCurrentIndex(index);
  };

  const prevSlide = () => {
    const lastIndex = data.length - itemsToShow;
    const shouldResetIndex = currentIndex === 0;
    const index = shouldResetIndex ? lastIndex : currentIndex - 1;
    setCurrentIndex(index);
  };

  return (
    <div className="carousel" ref={containerRef}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
      
    >
      <button onClick={prevSlide} className="arrow left-arrow">&#10094;</button>
      <div className="carousel-items" style={{ transform: `translateX(-${currentIndex * (60 / itemsToShow)}%)` }}>
        {data.map((item, index) => (
          <div key={index} className="carousel-item" style={{ width: `${60 / itemsToShow}%` }} ref={itemRef}>
            <div></div>
            <item.icon className="skills_item--svg_icon" style={{ ...item.style }} />
            <p className='skills_item--heading'>{item.name}</p>
          </div>
        ))}
      </div>
      <button onClick={nextSlide} className="arrow right-arrow">&#10095;</button>
    </div>
  );
};

export default Carousel;
